<p-menubar [model]="items">
  <ng-template #start>
    <img
      *ngIf="isSkoolDashboard"
      src="../../../../assets/skool_logo.png"
      alt="logo"
      style="width: 4rem; margin-right: 0.5rem; cursor: pointer"
      (click)="onHomeClick()"
    />
    <img
      *ngIf="!isSkoolDashboard"
      src="../../../../assets/logo.svg"
      alt="logo"
      style="width: 4rem; margin-right: 0.5rem; cursor: pointer"
      (click)="onHomeClick()"
    />
    <span *ngIf="!isSkoolDashboard" style="font-weight: bold; margin-left: 0.5rem">{{ portalHeader }}</span>
    <span *ngIf="isSkoolDashboard" style="font-weight: bold; margin-left: 0.5rem">Mein digitales Klassenzimmer</span>
  </ng-template>

  <ng-template #end>
    <app-usermenu></app-usermenu>
  </ng-template>
</p-menubar>

<router-outlet></router-outlet>
