import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AzureTenant } from '../../models/azureTenant';
import { DataService } from '@shared/services/data/data.service';

@Component({
  selector: 'app-tenant-selection',
  templateUrl: './tenant-selection.component.html',
  styleUrls: ['./tenant-selection.component.scss'],
})
export class TenantSelectionComponent implements OnInit {
  @Input() connectedTenants: AzureTenant[] = [];
  @Input() selectedTenantId: string | undefined;
  @Output() tenantSelected = new EventEmitter<string>();
  isLoading: boolean = true;

  constructor(public readonly dataService: DataService) {}

  ngOnInit() {
    this.isLoading = this.connectedTenants.length === 0;
  }

  ngOnChanges() {
    this.isLoading = this.connectedTenants.length === 0;
  }

  selectTenant(id: string) {
    this.tenantSelected.emit(id);
  }
}
