import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../../core/config/config.service';
import { ConfigDTO } from '../../../core/api/models/configDTO.model';
import { SESSION_STORAGE_KEYS } from 'app/core/session_storage/constants/session-storage-keys.constant';
import { DataService } from '@shared/services/data/data.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit {
  isConfigLoaded: boolean = false;

  constructor(
    private readonly configService: ConfigService,
    public readonly dataService: DataService,
  ) {}

  ngOnInit(): void {
    // Check if the user is in the onboarding process => onboarding active is set in the session storage
    const isCurrentlyOnboarding: string = sessionStorage.getItem(SESSION_STORAGE_KEYS.ONBOARDING_ACTIVE);

    if (isCurrentlyOnboarding !== null) {
      // We are in the onboarding process, so there is no config to load
      return;
    }

    this.configService.getOrganizationConfig().subscribe({
      next: (result: ConfigDTO) => {
        this.isConfigLoaded = result !== null;
      },
    });
  }
}
