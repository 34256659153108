import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MsalInterceptor } from '@azure/msal-angular';
import { ShellComponent } from './components/shell/shell.component';
import { UsermenuComponent } from './components/usermenu/usermenu.component';
import { ErrorInterceptorService } from './services/interceptors/error-interceptor.service';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ResourcesDetailComponent } from './components/resources-detail/resources-detail.component';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { TenantSelectionComponent } from './components/tenant-selection/tenant-selection.component';
import { FormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ChipModule } from 'primeng/chip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { DialogService } from 'primeng/dynamicdialog';
import { SidebarModule } from 'primeng/sidebar';
import { ToggleSwitchModule } from 'primeng/toggleswitch';
import { Popover } from 'primeng/popover';
import { TieredMenu } from 'primeng/tieredmenu';
import { DepartmentApiService } from 'app/core/api/services/department-api.service';

const components = [
  ShellComponent,
  UsermenuComponent,
  ConfigurationComponent,
  ResourcesDetailComponent,
  TenantSelectionComponent,
];

const modules = [
  CommonModule,
  LayoutModule,
  ReactiveFormsModule,
  RouterModule,
  ClipboardModule,
  MenuModule,
  MenubarModule,
  DividerModule,
  RadioButtonModule,
  ButtonModule,
  FormsModule,
  OverlayPanelModule,
  AvatarModule,
  AvatarGroupModule,
  ChipModule,
  ProgressSpinnerModule,
  TableModule,
  CardModule,
  IconFieldModule,
  InputIconModule,
  InputTextModule,
  ToggleSwitchModule,
  SidebarModule,
  Popover,
  TieredMenu,
];

@NgModule({
  declarations: [...components],
  imports: [...modules],
  exports: [...components, ...modules],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    DialogService,
    DepartmentApiService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class SharedModule {}
