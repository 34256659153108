import { User } from './../models/user';
import { Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Observable, of, Subject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { UserDTO } from 'app/core/api/models/userDTO';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  baseUrl = environment.baseUrl;

  private user: User;

  token: string;

  private readonly _destroying$ = new Subject<void>();

  isLoggedIn: Observable<boolean> = of(false);

  constructor(
    private authService: MsalService,
    private http: HttpClient,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  getStatus(): Observable<string> {
    return this.msalBroadcastService.inProgress$;
  }

  getUser(): User {
    return this.user;
  }

  login(): void {
    this.authService.loginRedirect();
  }

  getLoginStatus(): Observable<boolean> {
    return this.isLoggedIn;
  }

  logout(): void {
    localStorage.removeItem('currentUser');
    this.authService.logout();
  }

  private formatErrors(error: any): Observable<never> {
    return throwError(() => new Error(error.error));
  }

  getUserData(): Observable<User> {
    return this.http.get<User>(this.baseUrl + '/management/user').pipe(retry(3), catchError(this.formatErrors));
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  getAadProfilePicture(): Observable<any> {
    return this.http.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
      responseType: 'blob',
      headers: new HttpHeaders({ 'Content-Type': 'image/jpeg' }),
    });
  }

  getAadProfilePictureForUser(user: UserDTO | string): Observable<any> {
    let srcString: string = '';
    if (user !== null && typeof user !== 'undefined' && typeof user !== 'string') {
      srcString = user.profilePicture.toString();
    } else if (typeof user === 'string') {
      srcString = user;
    }
    return this.http
      .get(srcString, {
        responseType: 'blob',
        headers: new HttpHeaders({ 'Content-Type': 'image/jpeg' }),
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
      );
  }
}
