import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { AuthService } from '@shared/services/auth.service';
import { map, Observable, shareReplay } from 'rxjs';
import { Router } from '@angular/router';
import AppVersion from '../../../../assets/app-version.json';
import { DataService } from '@shared/services/data/data.service';
import { HttpClient } from '@angular/common/http';
import { AuthorizationService } from 'app/core/authorization/services/authorization.service';
import { SCOPES } from 'app/core/authorization/constants/scopes.constant';
import { OneDriveResponse } from '../../models/OneDriveResponse';
import { MenuItem } from 'primeng/api';
import { IsAdminService } from '@shared/services/is-admin.service';
import { IsInstanceAdminService } from '@shared/services/is-instance-admin.service';
import { ConfigService } from 'app/core/config/config.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, AfterViewInit {
  tenantName = '';

  portalHeader = 'Token Control';

  collapseIcon = 'pi pi-angle-right';

  isAdmin: boolean = null;

  isInstanceAdmin: boolean = null;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset]).pipe(
    map((result: BreakpointState) => result.matches),
    shareReplay(),
  );

  version = AppVersion.currentVersion;

  public isSkoolDashboard: boolean = false; // Just for the skool usecase

  private oneDriveLoginUrl: string = 'https://onedrive.live.com/login/';

  private graphApiUrl: string = 'https://graph.microsoft.com/v1.0/me/drive';

  items: MenuItem[] = [];

  menuItems: MenuItem[] = [];

  constructor(
    private http: HttpClient,
    private breakpointObserver: BreakpointObserver,
    private auth: AuthService,
    private router: Router,
    public dataService: DataService,
    readonly configService: ConfigService,
    private authorizationService: AuthorizationService,
    private isAdminService: IsAdminService,
    private isInstanceAdminService: IsInstanceAdminService,
    private cdr: ChangeDetectorRef, // Inject ChangeDetectorRef
  ) {
    this.tenantName = this.dataService.getCurrentTenantName();
    this.setCloudControlHeader();
  }

  ngOnInit(): void {
    this.isAdminService.isAdmin$.subscribe((status) => {
      this.isAdmin = status;
      // The SkoolDashboard flag is updated through the IsInstanceAdminService subscription below
      this.updateMenuItems();
    });

    this.isInstanceAdminService.isInstanceAdmin$.subscribe((status) => {
      this.isInstanceAdmin = status;
      this.isSkoolDashboard = this.isInstanceAdmin ? false : this.configService.getLoadUserGroups();
      this.updateMenuItems();
    });

    this.isSkoolDashboard = this.configService.getLoadUserGroups();
    this.updateMenuItems(); // Initial setup
  }

  ngAfterViewInit(): void {
    this.updateMenuItems(); // Ensure updateMenuItems is called after the view has been initialized
  }

  private updateMenuItems(): void {
    if (this.isAdmin === null) return;

    this.items = [
      {
        label: this.dataService.getTexts().NavBarLabelDashboard,
        icon: 'pi pi-home',
        routerLink: ['/dashboard'],
        visible: !this.isAdmin && !this.isInstanceAdmin,
      },
      {
        label: 'Home',
        icon: 'pi pi-home',
        routerLink: ['/admin/dashboard'],
        visible: this.isAdmin,
      },
      {
        label: this.getDepartmentNamePlural(),
        icon: 'pi pi-building-columns',
        routerLink: ['/admin/department'],
        visible: this.isAdmin,
      },
      {
        label: this.dataService.getTexts().NavBarLabelCostDashboard,
        icon: 'pi pi-money-bill',
        routerLink: ['/admin/cost'],
        visible: this.isAdmin,
      },
      {
        label: this.dataService.getTexts().AdminSettingsNavSideBarTitle,
        icon: 'pi pi-cog',
        routerLink: ['/admin/settings'],
        visible: this.isAdmin,
      },
      {
        label: 'Weblinks',
        icon: 'pi pi-th-large',
        items: [
          {
            label: this.dataService.getTexts().UserDashboardTitleExtern,
            icon: 'pi pi-globe',
            routerLink: ['/extern'],
            visible: this.isSkoolDashboard && !this.isAdmin && !this.isInstanceAdmin,
          },
          {
            label: this.dataService.getTexts().UserDashboardTitleWeblinks,
            icon: 'pi pi-graduation-cap',
            routerLink: ['/weblinks'],
            visible: this.isSkoolDashboard && !this.isAdmin && !this.isInstanceAdmin,
          },
          {
            label: this.dataService.getTexts().UserDashboardTitleM365,
            icon: 'pi pi-microsoft',
            routerLink: ['/m365'],
            visible: this.isSkoolDashboard && !this.isAdmin && !this.isInstanceAdmin,
          },
          {
            label: this.dataService.getTexts().UserDashboardTitleFavorite,
            icon: 'pi pi-bookmark',
            routerLink: ['/favorite'],
            visible: this.isSkoolDashboard && !this.isAdmin && !this.isInstanceAdmin,
          },
        ],
        visible: this.isSkoolDashboard && !this.isAdmin && !this.isInstanceAdmin,
      },
      {
        label: 'Passwort zurücksetzen',
        icon: 'pi pi-refresh',
        routerLink: ['/departments'],
        visible: this.isSkoolDashboard && !this.isAdmin && !this.isInstanceAdmin,
      },
    ];

    this.cdr.detectChanges(); // Trigger change detection after updating menu items
  }

  setCloudControlHeader(): void {
    let tenantName = this.dataService.getCurrentTenantName();
    if (tenantName !== undefined && tenantName !== null && tenantName !== '') {
      this.portalHeader = tenantName;
    }
  }

  getDepartmentNameSingular(): string {
    return this.configService.getAdminPortalDepartmentNameSingular();
  }

  getDepartmentNamePlural(): string {
    return this.configService.getAdminPortalDepartmentNamePlural();
  }

  onHomeClick(): void {
    if (this.isAdmin) {
      this.router.navigateByUrl('admin/dashboard');
    } else {
      if (this.isInstanceAdmin) {
        this.router.navigateByUrl('instances/dashboard');
      } else {
        this.router.navigateByUrl('dashboard');
      }
    }
  }

  goToMyOneDrive(): void {
    this.authorizationService.acquireToken([SCOPES.GRAPH_USER_READ]).subscribe(
      (result) => {
        const headers = { Authorization: `Bearer ${result.accessToken}` };
        this.http.get(this.graphApiUrl, { headers }).subscribe(
          (response: OneDriveResponse) => {
            const oneDriveUrl: string = response.webUrl;
            window.open(oneDriveUrl, '_blank');
          },
          (error) => {
            console.error(error);
            window.open(this.oneDriveLoginUrl, '_blank');
          },
        );
      },
      (error) => {
        console.error(error);
        window.open(this.oneDriveLoginUrl, '_blank');
      },
    );
  }

  signOut(): void {
    this.auth.logout();
  }
}
