import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { DepartmentDTO } from '@shared/models/departmentDTO';
import { Log } from 'app/features/admin/models/log';
import { CreateDepartmentRequestDTO } from '../models/createDepartmentRequestDTO.model';
import { UpdateDepartmentNameDescriptionDTO } from '../models/updateDepartmentNameDescriptionDTO.model';
import { DepartmentAiSettingsDTO } from '../models/departmentAiSettingsDTO.model';
import { ApiKeyDto } from '@shared/models/cost-control/costControlApiKeyDto';

@Injectable()
export class DepartmentApiService {
  private readonly baseUrl = environment.baseUrl;
  private readonly departmentControllerEndpoint = 'management/departments';

  constructor(private readonly http: HttpClient) {}

  createDepartment(createDepartmentRequest: CreateDepartmentRequestDTO): Observable<DepartmentDTO> {
    return this.http.post<DepartmentDTO>(this.baseUrl + this.departmentControllerEndpoint, createDepartmentRequest);
  }

  getDepartments(): Observable<DepartmentDTO[]> {
    return this.http.get<DepartmentDTO[]>(this.baseUrl + this.departmentControllerEndpoint + '/admin');
  }

  getDepartmentbyId(departmentId: string): Observable<DepartmentDTO> {
    return this.http.get<DepartmentDTO>(this.baseUrl + this.departmentControllerEndpoint + '/' + departmentId);
  }

  getLogsByDepartmentId(departmentId: string): Observable<Log[]> {
    return this.http.get<Log[]>(this.baseUrl + this.departmentControllerEndpoint + '/' + departmentId + '/logs');
  }

  updateDepartment(department: DepartmentDTO): Observable<DepartmentDTO> {
    return this.http.put<DepartmentDTO>(
      this.baseUrl + this.departmentControllerEndpoint + '/' + department.id,
      department,
    );
  }

  updateDepartmentAiSettings(departmentId: string, newAiSettings: DepartmentAiSettingsDTO): Observable<DepartmentDTO> {
    return this.http.put<DepartmentDTO>(
      this.baseUrl + this.departmentControllerEndpoint + '/' + departmentId + '/ai-settings',
      newAiSettings,
    );
  }

  addApiKeyToDepartment(departmentId: string, apiKeyName: string): Observable<ApiKeyDto[]> {
    const apiKey: ApiKeyDto = { name: apiKeyName };
    return this.http.post<ApiKeyDto[]>(
      this.baseUrl + this.departmentControllerEndpoint + '/' + departmentId + '/ai-settings/api-key',
      apiKey,
    );
  }

  deleteApiKeyFromDepartment(departmentId: string, apiKeyId: string): Observable<ApiKeyDto[]> {
    return this.http.delete<ApiKeyDto[]>(
      this.baseUrl + this.departmentControllerEndpoint + '/' + departmentId + '/ai-settings/api-key/' + apiKeyId,
    );
  }

  changeDepartmentName(departmentId: string, newName: string): Observable<DepartmentDTO> {
    const headers = { 'Content-Type': 'application/json' }; // Set the Content-Type header
    const updateDepartmentNameDescriptionDTO: UpdateDepartmentNameDescriptionDTO = {
      newNameOrDescription: newName,
    }; // Create a request body object with the new name

    return this.http.put<DepartmentDTO>(
      this.baseUrl + this.departmentControllerEndpoint + '/' + departmentId + '/name',
      updateDepartmentNameDescriptionDTO,
      { headers: headers },
    );
  }

  changeDepartmentDescription(departmentId: string, description: string): Observable<DepartmentDTO> {
    const headers = { 'Content-Type': 'application/json' }; // Set the Content-Type header
    const updateDepartmentNameDescriptionDTO: UpdateDepartmentNameDescriptionDTO = {
      newNameOrDescription: description,
    }; // Create a request body object with the new description

    return this.http.put<DepartmentDTO>(
      `${this.baseUrl}${this.departmentControllerEndpoint}/${departmentId}/description`,
      updateDepartmentNameDescriptionDTO,
      { headers: headers },
    );
  }

  deleteDepartment(departmentId: string): Observable<void> {
    return this.http.delete<void>(this.baseUrl + this.departmentControllerEndpoint + '/' + departmentId);
  }

  startVm(resourceId: string, departmentId: string): Observable<string> {
    return this.http.put<string>(
      this.baseUrl + this.departmentControllerEndpoint + '/' + departmentId + '/resources/' + resourceId + '/vms/start',
      null,
    );
  }

  restartVm(resourceId: string, departmentId: string): Observable<string> {
    return this.http.put<string>(
      this.baseUrl +
        this.departmentControllerEndpoint +
        '/' +
        departmentId +
        '/resources/' +
        resourceId +
        '/vms/restart',
      null,
    );
  }

  stopVm(resourceId: string, departmentId: string): Observable<string> {
    return this.http.put<string>(
      this.baseUrl + this.departmentControllerEndpoint + '/' + departmentId + '/resources/' + resourceId + '/vms/stop',
      null,
    );
  }
}
